import React, { ElementType, useMemo } from "react";
import { PageProps } from "gatsby";
import { useIntl } from "react-intl";

import { Layout } from "components/Layout";
import { Section } from "components/Section";
import { CustomLink } from "components/CustomLink";
import { Button } from "components/Button";
import SEO from "components/seo";

import withI18n from "i18n/withI18n";
import { CareerData } from "interface/sanityNode";
import { SLUG } from "constants/slug";
import { EXTERNAL_LINK } from "constants/externalLink";

import IconBack from "assets/ic_back_to_page.inline.svg";

interface DescriptionBlockProps {
  title: string;
  blocks: Array<{
    details: Array<string>;
    subTitle?: string;
    as?: ElementType;
  }>;
}

const DescriptionBlock = ({ title, blocks }: DescriptionBlockProps) => {
  return (
    <div className="mb-2 flex flex-col lg:mb-6">
      <h3 className="font-semibold text-primary lg:mb-4 lg:text-2xl">{title}</h3>
      {blocks.map(
        ({ subTitle, details, as: Tag = "ul" }) =>
          details?.length > 0 && (
            <Tag
              className={`
                mb-4 tracking-wide lg:text-xl lg:leading-10 
                ${Tag === "ol" ? "list-decimal" : "list-disc"}`}
              key={details?.[0]}
            >
              {subTitle && <h6 className="mt-2">{subTitle}</h6>}
              {details?.map((d) => (
                <li key={d} className="ml-10">
                  {d}
                </li>
              ))}
            </Tag>
          )
      )}
    </div>
  );
};

interface JobDetailPageContext {
  data: CareerData;
}

const JobDetailPage = ({ pageContext, location }: PageProps) => {
  const { formatMessage } = useIntl();
  const { data } = pageContext as JobDetailPageContext;
  const blocks = useMemo(() => {
    const {
      responsibilities,
      minimumRequirements,
      preferredRequirements,
      niceToHaveRequirements,
      interviewProcess,
    } = data;

    return [
      {
        title: formatMessage({ id: "JOB_DEATIL_RESPONSIBILITIES" }),
        blocks: [{ details: responsibilities }],
      },
      {
        title: formatMessage({ id: "JOB_DEATIL_REQUIREMENTS" }),
        blocks: [
          {
            subTitle: formatMessage({ id: "CAREER_POSITION_REQUIREMENTS_MINIMUM" }),
            details: minimumRequirements,
          },
          {
            subTitle: formatMessage({ id: "CAREER_POSITION_REQUIREMENTS_PREFERRED" }),
            details: preferredRequirements,
          },
          {
            subTitle: formatMessage({ id: "CAREER_POSITION_REQUIREMENTS_NICETOHAVE" }),
            details: niceToHaveRequirements,
          },
        ],
      },
      {
        title: formatMessage({ id: "CAREER_POSITION_INTERVIEWPROCESS" }),
        blocks: [{ details: interviewProcess, as: "ol" }],
      },
    ];
  }, [data, formatMessage]) as Array<DescriptionBlockProps>;

  return (
    <>
      <SEO
        title={data.positionName}
        titlePrefix={formatMessage({ id: "JOB_DEATIL_PAGE_TITLE_PREFIX" })}
        pathname={location.pathname}
        locale
      />
      <Layout>
        <Section>
          <div className="flex flex-col py-24 xl:pt-36 xl:pb-48">
            <div
              className="
                relative order-first mb-3 flex items-center justify-center border-b border-btq-sub-text-normal pb-5 
                lg:mb-0 lg:flex-col lg:items-stretch lg:justify-start lg:border-none lg:pb-0"
            >
              <div className="absolute left-0 mr-5 origin-center self-start text-primary lg:relative lg:mb-9 lg:mr-0">
                <CustomLink href={SLUG.CAREER} className="cursor-pointer">
                  <IconBack />
                </CustomLink>
              </div>
              <h2
                className="
                  text-center text-2xl font-semibold tracking-wide md:text-3xl 
                  lg:mb-5 lg:translate-y-0 lg:border-b lg:border-btq-sub-text-normal lg:pb-5 lg:text-5xl"
              >
                {data.positionName}
              </h2>
            </div>

            <div
              className="
                order-2 self-end tracking-wide text-btq-sub-text-normal 
                lg:mb-5 lg:text-xl lg:leading-6"
            >
              {`${formatMessage({ id: "JOB_DEATIL_LOCATION" })}${data.location}`}
            </div>

            <div className="order-last mt-7 self-center lg:order-3 lg:mt-0 lg:self-end">
              <a href={`mailto:${EXTERNAL_LINK.JOB_APPLY_EMAIL}`}>
                <Button className="sm:px-10 sm:py-4">
                  {formatMessage({ id: "CAREER_APPLICATION_APPLY_NOW" })}
                </Button>
              </a>
            </div>
            <div className="order-4 flex flex-col">
              {blocks.map((block) => (
                <DescriptionBlock {...block} key={block.title} />
              ))}
            </div>
            <div className="order-5 lg:text-center xl:mt-9 ">
              <div className="text-xl text-primary xl:text-2xl">
                {formatMessage({ id: "CAREER_APPLICATION_FOLLOWUP" })}
              </div>
              <div className="lg:text-xl">
                {formatMessage({ id: "CAREER_APPLICATION_REACH_US" })}
              </div>
            </div>
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default withI18n(JobDetailPage);
